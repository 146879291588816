import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import actions from '../../../Assets/dataStore/dataStoreAction';
import './SelectSubjectButton.scss'

export default function SelectSubjectButton(props) {
    const essentials = useSelector(state => state.essential)
    const dispatch = useDispatch();
    useEffect(()=>{
        window.scrollTo(0,0)
    },[])
    const handleSubjectClick = (id,subject) =>{
        const subs = ["m","p","c","b"];
        for(var i=0;i<4;i++){
            if(subs[i] !== id){
                document.getElementById(subs[i]).classList.remove('subject-active')
                document.getElementById(subs[i]+"w").classList.remove('image-white')
                document.getElementById(subs[i]+'b').classList.remove('image-black')
            }else{
                document.getElementById(subs[i]).classList.toggle('subject-active')
                document.getElementById(subs[i]+"w").classList.toggle('image-white')
                document.getElementById(subs[i]+'b').classList.toggle('image-black')
            }
        }
        if(essentials.hasOwnProperty('subject')){
            if(essentials.subject === subject){
                dispatch(actions.deleteSubject())
            }else{
                dispatch(actions.storeSubject(subject));
            }
        }else{
            dispatch(actions.storeSubject(subject));
        }
    }
    return (

        <div className="subject_button" >
            <button className="subject_name" id={props._id} onClick={() => handleSubjectClick(props._id,props.subject)}>
                <img src={props.subjectImage} id={props._id+'b'} alt=""/>
                {<img src={props.subjectImageWhite} className="image-two" id={props._id+"w"} alt=""/>}
                {props.subject}
            </button>
        </div>
    )
}
