import React from 'react'
import './More.scss'
import MoreCard from './MoreCard/MoreCard'
export default function More() {
    return (
        <div className="more_container" id="personalised-tution">
            <h1>There Is More...</h1>
            <MoreCard
            MoreImage="/images/More/reading.png"
            Morepara="Comprehensive suite of reports allows you to track and monitor your child’s progress through their course."
            />
             <MoreCard
            MoreImage="/images/More/online-test.png"
            Morepara="An online programme allows you to access the lessons from any device with an internet connection. No travelling to tuition centres "
            />
             <MoreCard
            MoreImage="/images/More/graduation-cap.png"
            Morepara="Improve crucial skills such as time management, how to attempt questions, etc."
            />
             <MoreCard
            MoreImage="/images/More/testing.png"
            Morepara="Comprehensive study material prepared by our highly experienced JEE trainers"
            />
        </div>
    )
}
