import React from "react";
import PreparationCard from "./PreparationCard/PreparationCard";
import "./Preparation.scss";
export default function preperation() {
  return (
    <div className="preparation_container" id="classroom-program">
      <h1>
        Start your Maths and science preparation with<br/> Revamp24 personalised live classes.
      </h1>
      <div className="preparation_main">
        <PreparationCard
          cardTitle="BEST Live online classroom programme"
          cardImage="/images/Preparation/violetkcet.png"
          Title="Regular online classes by India's leading Maths and Science trainers"
          Title2="Indepth discussion of Daily Practice Problems (DPPs) & Practice Sheets"
          Title3="Chapterwise Tests to understand the concepts"
          prepareParagraph="Online Classes will be conducted 3-5 times a week by India's Best Maths and science  Trainers. This will help you understand every concept thoroughly and ensure that all your doubts are cleared."
          prepareParagraph2="The well-designed and structured DPP will help you revise the concepts every week for all the topics. Practice Sheet discussion ensures that you learn to apply the concepts taught in the class."
          prepareParagraph3="Better your performance by using chapterwise tests, result analysis, recommendations etc,In chapter test, questions from every concept is given to test your understanding for each concept."
          curveImage="/images/Preparation/violet1.png"
          straightImage="/images/Preparation/png-feedback.png"
          downImage="/images/Preparation/exam.png"
          titleCard="card-title"
        />
        <PreparationCard
          cardTitle="Personalised live casses and mentoring"
          cardImage="/images/Preparation/orangemain.png"
          Title="LIVE online tutoring"
          Title2="Online study materials"
          Title3="Quick doubt resolution"
          prepareParagraph="Interact with teachers via live video classes, learn in real-time and understand concepts easily."
          prepareParagraph2="Learn with the help of easy to understand online study materials compiled by experts in the field."
          prepareParagraph3="Convenient and instant doubt resolution by expert faculty during and after class."
          curveImage="/images/Preparation/teach.png"
          straightImage="/images/Preparation/feedback.png"
          downImage="/images/Preparation/orangeexam.png"
          titleCard="card-title2"
        />
      </div>
    </div>
  );
}
