import React from "react";
import BestCard from "./BestCard/BestCard";
import BookingForm from './BookingForm/BookingForm'

import "./LandingPage.scss";

export default function Landing() {
  return (
    <div className="landing_container" id="my-section">
      <div className="landing_first">
        <div className="landing-content-wrapper">

        <h1 className="head">Revamp24 classes For Maths and science.</h1>
        <h1 className="subhead">GET THE BEST OF</h1>

        <div className="landing_main">
          <BestCard
            cardImage="/images/Landing/online.png"
            cardTitle="Live classes from Top Maths and science experts"
          />
          <BestCard
            cardImage="/images/Landing/mentor.png"
            cardTitle="Personalised individual tuition"
          />
        </div>
        <div className="landing_para">
          <p>
            Live personalised online classes for Maths and science across all boards.(CBSE,ICSE,IGCSE)
          </p>
        </div>
      </div>
      </div>
      <div className="landing_form">
        <BookingForm/>
      </div>
      
      
    </div>
  );
}
