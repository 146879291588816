import React, { useState } from 'react'
import './Navbar.scss'
import {AiFillTwitterCircle} from 'react-icons/ai'
import {FaFacebook ,FaTelegram} from 'react-icons/fa'
import {IoLogoWhatsapp} from 'react-icons/io'

export default function Navbar() {
    const [navMobile, setNavMobile] = useState('')

    const handleNavLink = (id) => {
        document.getElementById(id).scrollIntoView({ behavior: 'smooth', block: 'start' })
    }
    const navMobileHandle = () => {
        if (navMobile === 'nav-active') {
            setNavMobile("")
        } else {
            setNavMobile("nav-active")
        }
    }
    const shareMobile = async () =>{
        const shareData = {
            title: 'Revamp24',
            text: 'Live personalised online classes for Maths and science across all boards.(CBSE,ICSE,IGCSE)',
            url: 'https://study.revamp24.in',
          }
        try{
            await navigator.share(shareData)
        }catch(err){
            //no code
        }
    }
    return (
        <div className="navbar">
            <div className="for-mobile">
                <div className="burger" onClick={navMobileHandle}>
                    <div className="line"></div>
                    <div className="line"></div>
                    <div className="line"></div>
                </div>
                <div className="logo-section">
                    <img src="/images/Logo/Logo.png" className="logo" alt="" />
                </div>
                <img src="/images/Navbar/share.png" className="mobile-share" onClick={shareMobile} alt="" />
            </div>
            <ul className={navMobile}>
                <li onClick={() => handleNavLink("classroom-program")}>Classroom Program</li>
                <li onClick={() => handleNavLink("personalised-tution")}>Personalised Focus</li>
                <li onClick={() => handleNavLink("study-materials")}>Sample study materials</li>
                <li onClick={() => handleNavLink("results")}>Results</li>
                <li>
                    <img src="/images/Navbar/share.png" className="share" alt="" />
                    <ul className="share-dropdown">
                        <li className="whatsapp-logo" onClick={()=> window.open("https://web.whatsapp.com/send?text=https://study.revamp24.in  Live personalised online classes for Maths and science across all boards.(CBSE,ICSE,IGCSE)")}>
                            <IoLogoWhatsapp/>
                        </li>
                        <li className="facebook-logo" onClick={()=> window.open("https://www.facebook.com/sharer.php?u=https://study.revamp24.in&title=Live personalised online classes for Maths and science across all boards.(CBSE,ICSE,IGCSE)")}>
                            <FaFacebook/>
                        </li>
                        <li className="twitter-logo" onClick={()=> window.open("https://twitter.com/intent/tweet?url=https://study.revamp24.in&via=Revamp24")}>
                            <AiFillTwitterCircle/>
                        </li>
                        <li className="telegram-logo" onClick={()=> window.open("https://telegram.me/share/url?url=https://study.revamp24.in&text=Live personalised online classes for Maths and science across all boards.(CBSE,ICSE,IGCSE)")}>
                            <FaTelegram/>
                        </li>
                    </ul>
                </li>
            </ul>
        </div>
    )
}
