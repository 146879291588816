import React from 'react'
import './LearnAndPractice.scss'
import LearnDownloadCard from './LearnDownloadCard/LearnDownloadCard'

export default function LearnAndPractice() {
    return (
        <div className="learn-and-practice" id="study-materials">
            <h1 className="learn-head">LEARN AND PRACTICE THROUGH OUR REGULAR CLASSES, DPPs AND MORE</h1>
            <div className="learn-cards-wrapper">
                <LearnDownloadCard 
                    cardHead="View sample questions"
                    downloadOne="/Documents/Algebraic Expressions and Identities QP_Class 8.pdf"
                    downloadTwo="/Documents/class 9.pdf"
                    downloadThree="/Documents/Introduction to Trigonometry QP Class 10.pdf"
                    btn1="Class 8"
                    btn2="Class 9"
                    btn3="Class 10"
                />
                <LearnDownloadCard 
                    cardHead="View sample questions"
                    downloadOne="/Documents/Conic Sections.Class 11 QP.pdf"
                    downloadTwo="/Documents/Chemical Kinetics QP_Class 12.pdf"
                    downloadThree="/Documents/15-Electric Charges And Fields_JEE_NEET.pdf"
                    btn1="Class 11"
                    btn2="Class 12"
                    btn3="JEE/NEET"
                />
            </div>
        </div>
    )
}
