import React from 'react'
import './LearnCardBtn.scss'

export default function LearnCardBtn({link,btnText}) {
    return (
         <a href={link} download className="learn-card-btn">
             <img src="/images/pdfPart/pdf.png" alt=""/>
             {btnText}
         </a>
    )
}
