import React from 'react'
import LearnCardBtn from '../LearnCardButton/LearnCardBtn'
import './LearnDownloadCard.scss'

export default function LearnDownloadCard({cardHead, downloadOne, downloadTwo, downloadThree,btn1, btn2, btn3}) {
    return (
        <div className="learn-download-card">
            <h3>{cardHead}</h3>
            <LearnCardBtn link={downloadOne} btnText={btn1}/>
            <LearnCardBtn link={downloadTwo} btnText={btn2}/>
            <LearnCardBtn link={downloadThree} btnText={btn3}/>
        </div>
    )
}
