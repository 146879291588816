import {BrowserRouter as Router, Switch,Route} from 'react-router-dom';
import { useEffect } from 'react';
import Home from './Components/Home/Home';
import SchedulePage from './Components/SchedulePage/SchedulePage';
import ThanksPage from './Components/ThanksPage/ThanksPage';
import Navbar from './Components/Navbar/Navbar'

function App() {

  useEffect(() => {
    const chatBot = document.createElement('script');
    chatBot.innerHTML = "(function(I, L, T, i, c, k, s) {if(I.iticks) return;I.iticks = {host:c, settings:s, clientId:k, cdn:L, queue:[]};var h = T.head || T.documentElement;var e = T.createElement(i);var l = I.location;e.async = true;e.src = (L||c)+'/client/inject-v2.min.js';h.insertBefore(e, h.firstChild);I.iticks.call = function(a, b) {I.iticks.queue.push([a, b]);};})(window, 'https://cdn.intelliticks.com/prod/common', document, 'script', 'https://app.intelliticks.com', '36fHDtKhjxHQ82J5X_c', {});"

    document.body.appendChild(chatBot)
  }, [])

  return (
     <Router>
       <Switch>
         <Route path="/" exact>
            <Navbar/>
            <Home/>
         </Route>
         <Route path="/schedule">
           <SchedulePage/>
         </Route>
         <Route path="/thanks">
           <ThanksPage/>
         </Route>
       </Switch>
     </Router>
  );
}

export default App;
