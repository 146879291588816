import React, { useEffect, useState } from "react";
import "./ResultSection.scss";
import { IoIosArrowForward, IoIosArrowBack } from 'react-icons/io'

export default function ResultSection() {
    const [transition,setTransition] = useState("")
    const [mobile,setMobile] = useState(null)
    const [trigger,setTrigger] = useState(false)

    useEffect(() => {
        windowSizeChecker()
        setTimeout(()=>{
            setTrigger(!trigger)
        },3000)
        trigger && automaticSlide()
    },[trigger])



    window.addEventListener('resize',()=>{
        windowSizeChecker()
    })

    const automaticSlide = () =>{
        switch(transition){
            case "":
                setTransition("t-100")
                break;
            case "t-100":
                setTransition("t-200")
                break;
            case "t-200":
                setTransition("")
                break;
            default:
                break;
        }
    }

    // *Function for check the width of window and give result whether its mobile or PC
    const windowSizeChecker = () =>{
        if(window.innerWidth > 700){
            setMobile(false)
        }else{
            setMobile(true)
        }
    }

    // *Function used for slide the images when clicking on left or right button
    const handleSlide = event =>{
        if(event.target.classList[0] === "forward-icon"){
            switch(transition){
                case "":
                    setTransition("t-100")
                    break;
                case "t-100":
                    setTransition("t-200")
                    break;
                default:
                    break;
            }
        }else{
            switch(transition){
                case "t-200":
                    setTransition("t-100");
                    break;
                case "t-100":
                    setTransition("");
                    break;
                default:
                    break;
            }
        }
    }

    return (
        <div className="result-section" id="results">
            <h2>Our Results</h2>
            <div className="slide-container">
                <IoIosArrowBack className="backward-icon" onClick={handleSlide}/>
                <div className="slide-wrapper">
                    <div className={"slide "+transition}>
                        <div className="slide-image-wrapper">
                            <img
                                src={`/images/result/competitive exam results ${mobile?"mobile":"PC"}.jpeg`}
                                alt=""
                            />
                        </div>
                        <div className="slide-image-wrapper">
                            <img src={`/images/result/12th board result ${mobile?"mobile":"pc"}.jpeg`} alt="" />
                        </div>
                        <div className="slide-image-wrapper">
                            <img src={`/images/result/10th board result ${mobile?"mobile":"pc"}.jpeg`} alt="" />
                        </div>
                    </div>
                </div>
                <IoIosArrowForward className="forward-icon" onClick={handleSlide}/>
            </div>
        </div>
    );
}
