import { React, useState } from "react";
import BookingForm from "../Home/LandingPage/BookingForm/BookingForm";
import "./EnquireSection.scss";

export default function EnquireSection() {
  const [buttonPopup, setButtonPopup] = useState(false);
  return (
    <div className="enquire-section">
      <div className="enquire-content-wrapper">
        <p className="first-text">
          The all-new Maths and science preparation course - REVAMP24 Classes - is built on REVAMP24 expertise in cutting-edge educational technology. This course offers you the Best Classroom Learning experience and Highly Personalised Attention required to study maths and science.
        </p>
        <p className="last-text">
          To Study maths and science , REVAMP24 Classes is all you need!
        </p>
        <button onClick={() => setButtonPopup(!buttonPopup)} className="enquire-btn">
          Book Now
        </button>
        <div className="pop-form">
          <div className="pop-form-wrapper">
            {
              buttonPopup &&
              <BookingForm />

            }

          </div>
        </div>
      </div>
    </div>
  );
}
