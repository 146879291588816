import React, { useEffect } from 'react'
import './ThanksPage.scss'
import { AiOutlineCloseCircle } from 'react-icons/ai'
import { Link } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import actions from '../../Assets/dataStore/dataStoreAction'
import { Helmet } from 'react-helmet'
const ThanksPage = () => {
    const dispatch = useDispatch()
    window.dataLayer = window.dataLayer || [];
    useEffect(() => {
        dispatch(actions.reload())
        window.dataLayer.push({
            "event": 'LP2TrialclassSheduled',
            "pageURL": 'https://study.revamp24.in/thanks',
            'pageTitle': "Revamp24 LP2 | Scheduled"
        })
    }, [dispatch])
    return (
     <>
     <Helmet>
         <title>Revamp24 | Thankyou</title>
     </Helmet>
        <div className="thanksPageContainer">
            <div className="thanks-card">
                <Link to="/">
                    <AiOutlineCloseCircle className="close-btn"/>
                </Link>
                <div className="logo">
                    <img src="/logo/logo.png" alt=""/>
                    <p>"No student is left behind"</p>
                </div>
                <p className="interest">Thanks for showing interest in <br/>Revamp24!</p>
                <p className="academic">An academic counsellor will contact you through the number provided to help you schedule a free trial.</p>
            </div>
        </div>
     </>
    )
}

export default ThanksPage;
